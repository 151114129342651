<template>
    <div class="wrapper">
        <MyHeader/>

        <div class="container" style="margin-top: 50px; margin-bottom: 100px;" >
            <router-view/>
        </div>

        <MyFooter/>
    </div>
</template>

<script>
import MyHeader from "@/views/PageHeader.vue";
import MyFooter from "@/views/PageFooter.vue";

export default {
    name: "PageBuild",
    components: {
        MyHeader,
        MyFooter,
    }
};
</script>

<style scoped>
.wrapper {
    display: block;
    position: relative;

}
</style>
