<template>
    <Menu mode="horizontal" theme="light" active-name="home">
        <div class="container">
            <a href="#/" target="_self">
                <img class="logo-img" src="@/assets/img/logo2.png"/>
            </a>

            <div  style="float: right">
                <MenuItem name="home" to="/">
                    <Icon type="ios-home" />
                    主页
                </MenuItem>
                <MenuItem name="course" :to="docs_url" target="_blank">
                    <Icon type="ios-book" />
                    使用教程
                </MenuItem>
                <MenuItem name="changelog" :to="changelog_url" target="_blank">
                    <Icon type="md-git-branch" />
                    更新日志
                </MenuItem>
                <MenuItem
                    name="github"
                    :to="github_url"
                    target="_blank"
                >
                    <Icon type="logo-github" />
                    github
                </MenuItem>
            </div>
        </div>
    </Menu>
</template>
<script>
import axios from "axios";

export default {
    name: "PageHeader",
    components: {},
    mounted: function () {
        this.getData();
    },
    methods: {
        getData() {
            axios.get("../data.json?t=" + new Date().getTime()).then((response) => {
                (this.docs_url = response.data.docs_url),
                    (this.changelog_url = response.data.changelog_url),
                    (this.github_url = response.data.github_url);
                // (this.DLLinkShuffle());
            });
        },
    },
    data() {
        return {
            docs_url: "",
            changelog_url: "",
            github_url: "",
        };
    },
};
</script>
<style scoped>
/*.wrapper-header {*/
/*    position: fixed;*/
/*    width: 100%;*/
/*    top: 0;*/
/*    right: 0;*/
/*    z-index: 1000;*/
/*}*/

/*.wrapper-header-nav {*/
/*    width: 95%;*/
/*    height: 65px;*/
/*    margin: 0 auto;*/
/*}*/

/*.layout-logo {*/
/*    width: 150px;*/
/*    height: 50px;*/
/*    !* background: #5b6270; *!*/
/*    !* background: #f5f7f9; *!*/
/*    border-radius: 3px;*/
/*    float: left;*/
/*    position: relative;*/
/*    top: 5px;*/
/*    left: 20px;*/
/*}*/

.logo-img {
    width: 150px;
    height: 50px;
}

/*.ivu-layout-header {*/
/*    !* background: #f5f7f9; *!*/
/*    background-color: #ffffff;*/
/*}*/
</style>
