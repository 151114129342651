<template>
    <div class="footer">2020-2024 &copy; 团子翻译器</div>
</template>

<script>
export default {
    name: "PageFooter",
    components: {},
};
</script>

<style scoped>
.footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 60px;
    line-height: 60px;
    background-color: #f5f5f5;
    text-align: center;
    color: #777;
}
</style>
